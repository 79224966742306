<template>
  <div>
    <div>{{ field.Value }}</div>
  </div>
</template>

<script>
export default {
  props: {field: {required: true, type: Object}},
};
</script>
